/* .accordion {
 background-color: #f8f8f8;
  color: #444;
  cursor: pointer;
  padding: 7px;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 14px;
  text-align: left;
  outline: none;
  font-weight: 600;
  font-family: 'Roboto Condensed', sans-serif;
  transition: 0.4s;
  overflow: paged-y;
  box-shadow: 0 0 2px 0 rgba(57, 78, 234, 0.1) 
}
.active, .accordion:hover {
  color: #ffffff;
  font-weight: 400;
  background-color: #051b35;
}
.accordion:after {
  content: '\276F';
  color: #c2c2c2;
  float: right;
  margin-left: 5px;
}
.active:after,hover {
  color: #ffffff;
  content: "\276E";
}
.panel {
  padding: 0 10px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.accordionMenuDiv{
  width: 100%;
  direction:rtl;
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: 300 !important;
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e9e9e9 #ffffff;
} */
/* */
.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #f8f8f8;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 3px;
  --bs-accordion-btn-padding-y: 3px;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8e8e8f;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-btn-active-bg:#000;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}
 

.accordion-header{
  background-color: #f8f8f8;
  color: #444;
  cursor: pointer;
  padding: 0px;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 14px;
  text-align: left;
  outline: none;
  font-weight: 600;
  font-family: 'Roboto Condensed', sans-serif;
  transition: 0.4s;
  overflow: paged-y;
  box-shadow: 0 0 2px 0 rgba(57, 78, 234, 0.1) 
} 
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  padding: 1px 5px;
  font-size: 0.88rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-button:hover{
  color: #fff;
  background-color: #000;
}



.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #000;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  box-shadow: none;
  
}
/*/**/
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
 
  
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  border: none;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
} 


.accordion-body{
  padding: 10px;
}
.accordionMenuIcon{
  width: 20px;
  height: auto;
}  
.accordion-button:not(.collapsed)  .accordionMenuIcon{
  color: #fff;
}

.accordion-button Link{
  color:#000;
}

/* .accordionMenuDiv::-webkit-scrollbar {
  width:10px !important;
}

.accordionMenuDiv::-webkit-scrollbar-thumb {
  background: #dedede !important;
}

.accordionMenuDiv::-webkit-scrollbar-thumb:hover {
  background: #808080 !important;
}

.accordionMenuDiv::-webkit-scrollbar-track {
  background: #F4F4F4!important;
}


.accordionMenuDivInside{
  direction:ltr;
}

.accordionItem{
  color: #444;
  text-decoration: none;
}
.accordionItem:hover{
  text-decoration: none;
} */