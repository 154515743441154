@media (max-width: 374.98px) { 

  .Mobile{
    
  }
  .Desktop{
       display: none;
      
  }

  
}

@media (min-width: 375.98px) and (max-width: 575.98px){
  .Mobile{

  }
  .Desktop{
       display: none;
       }  

}


@media (min-width: 576px) and (max-width: 767.98px) { 
  .Mobile{

  }
  .Desktop{
       display: none;
  }

}


@media (min-width: 768px) and (max-width: 991.98px) {
  .Mobile{
       display: none;
  }
  .Desktop{

  }


}


@media (min-width: 992px) and (max-width: 1199.98px) {
  .Mobile{
       display: none;
  }
  .Desktop{

  }

}


@media (min-width: 1200px) { 
  .Mobile{
       display: none;
  }
  .Desktop{

  }

}