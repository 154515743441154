
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Roboto+Condensed:wght@300;400;700&display=swap");

@import '/node_modules/bootstrap/dist/css/bootstrap.css';

@import "bigSlider.css";
@import "button.css";
@import "card.css"; 
@import "megaMenu.css";
@import "navMenuDesktop.css";
@import "navMenuMobile.css";
@import "typo.css";
@import "responsive.css"; 
@import "footer.css"; 
@import "onboard.css"; 
@import "common.css"; 
@import "productDetails.css"; 
@import "notification.css"; 







body{
  background-color: #fff;
 
}